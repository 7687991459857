.hero {
  display: flex;
  justify-content: space-between;
}

.left-h {
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 2.5;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.right-h {
  flex: 1;
  position: relative;
  background-color: var(--orange);
}

.the-best-add {
  margin-top: 4rem;
  border-radius: 4rem;
  width: fit-content;
  background-color: #363d42;
  padding: 20px 13px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.the-best-add > span {
  z-index: 2;
}

.the-best-add > div {
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 4.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  text-overflow: inherit;
}

.hero-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}


.figures{
    display: flex;
    gap: 2rem;
    margin-top: 4rem;
}

.figures>div{
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;

}

.figures>div>span:nth-of-type(2){
   color: var(--gray);
   text-transform: uppercase;
}

.hero-buttons{
    display: flex;
    gap: 1.5rem;
    font-weight: normal;
}

.hero-buttons>:nth-child(1){
    color: #fff;
    background-color: var(--orange);
    width: 12rem;
    padding: 1rem;
}

.hero-buttons>:nth-child(2){
    color: #fff;
    background-color: transparent;
    width: 12rem;
    border: 2px solid orange;
}

.right-h>.btn{
  position: absolute;
  right: 3rem ;
  top: 2rem;
  color: black;
  font-size: 1.2rem;
}

.heart-rate{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    background-color: var(--darkGrey);
    width: 8rem;
    padding: 1.5rem;
    align-items: start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}

.heart-rate>span:nth-child(2){
    color: var(--gray);
}

.heart-rate>span:nth-child(3){
    color: white;
    font-size: 1.5rem;
}

.heart-rate>img{
    width: 3rem;
}


.hero-image{
    position: absolute;
    right: 12rem;
    top: 9rem;
    width: 30rem;
}

.hero-image-back{
    position: absolute;
    z-index: -1;
    top: 5rem;
    right: 28rem;
    width: 16rem;
}

.calories{
    display: flex;
    gap: 1rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1.5rem;
    width:16rem;
    position: absolute;
    top: 33rem;
    right: 42rem;
}

.calories> img{
    width: 4rem;
}

.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories > div >:nth-child(1){
   color: var(--gray);
   font-size: 1.2rem;
   padding-left: 10px;
}

.calories > div >:nth-child(2){
    color: white;
    font-size: 1.8rem;
    padding-left: 10px;
 }


 .hero-blur{
  width: 22rem;
  height: 30rem;
  left: 0;
 }

 @media screen and (max-width:896px) {
  .hero{
    flex-direction: column;
  }
  .hero-blur{
    width: 14rem;
  }
  .the-best-add{
    margin-top: 0rem;
    align-items: center;
   font-size: small;
   transform: scale(0.8);
   margin-left: 2.5rem;
  }
  .hero-text{
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero-text>:nth-child(3){
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .hero-buttons{
    justify-content: center;
  }
  .figures>div>span:nth-of-type(1){
    font-size: larger;
  }
  .figures>div>span:nth-of-type(2){
    font-size: small;
  }

  .right-h{
    position: relative;
    background: none;
  }

  .heart-rate{
    left: 1rem;
    top: 2rem;
  }
  .calories{
    position: relative;
    top: 10rem;
    left: 3.5rem;
  }
  .calories>img{
    width: 2rem;
  }
  .calories>div>:nth-child(2){
    color: white;
    font-size: 1rem;
  }
  .hero-image{
    position: relative;
    width: 15rem;
   margin-left: 20rem;
  }
  .hero-image-back{
    width: 15rem;
    left: 3.6rem;
    top: 0rem;
  }
 }